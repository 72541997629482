import React from 'react';
import { Link } from 'gatsby';

import { LogoIcon } from '../utils/icons';

const Footer = ({ withSocial }) => {
	return (
		<footer className="footer">
			<div className="footer__logo">
				<Link to="/">
					<LogoIcon size={48} />
				</Link>
			</div>
			<div className="footer__center-content">
				© Dean Nellis {new Date().getFullYear()}
			</div>
			{withSocial && (
				<div className="footer__right-content">
					<a href="https://www.instagram.com/" target="blank">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path
								d="M256 72.19a684.2 684.2 0 0189.41 2.49 115.24 115.24 0 0142.22 7.44 87.25 87.25 0 0142.22 42.22 115.21 115.21 0 017.47 42.25c0 22.35 2.49 29.79 2.49 89.41a684.2 684.2 0 01-2.49 89.41 115.24 115.24 0 01-7.44 42.22 87.25 87.25 0 01-42.22 42.22 115.22 115.22 0 01-42.21 7.44c-22.36 0-29.8 2.49-89.42 2.49a686 686 0 01-89.41-2.49 115.24 115.24 0 01-42.22-7.44 87.19 87.19 0 01-42.21-42.22 115.09 115.09 0 01-7.51-42.22c0-22.35-2.49-29.79-2.49-89.41a684.2 684.2 0 012.49-89.41 115.24 115.24 0 017.44-42.22 89.1 89.1 0 0117.41-24.84 41.94 41.94 0 0124.84-17.38 115.27 115.27 0 0142.22-7.47A684.2 684.2 0 01256 72.19m0-39.73A734.09 734.09 0 00164.1 35a153.1 153.1 0 00-54.64 9.93 97.31 97.31 0 00-39.73 24.8 97.31 97.31 0 00-24.84 39.73A113.11 113.11 0 0035 164.1a734.09 734.09 0 00-2.5 91.9 734.09 734.09 0 002.5 91.9 153.1 153.1 0 009.93 54.64 97.31 97.31 0 0024.84 39.73 97.31 97.31 0 0039.73 24.84 153.1 153.1 0 0054.6 9.89 734.09 734.09 0 0091.9 2.5 734.09 734.09 0 0091.9-2.5 153.1 153.1 0 0054.64-9.93 104.18 104.18 0 0064.57-64.57 153.1 153.1 0 009.89-54.6c0-24.84 2.5-32.28 2.5-91.9a734.09 734.09 0 00-2.5-91.9 153.1 153.1 0 00-9.93-54.64 97.31 97.31 0 00-24.84-39.73 97.31 97.31 0 00-39.73-24.84A153.1 153.1 0 00347.9 35a734.09 734.09 0 00-91.9-2.5m0 109.28a112.41 112.41 0 00-114.26 110.48V256A114.26 114.26 0 10256 141.74m0 188.76a73.19 73.19 0 01-74.5-71.86V256a73.19 73.19 0 0171.86-74.5H256a73.19 73.19 0 0174.5 71.86V256a73.19 73.19 0 01-71.86 74.5H256m119.21-221a27.34 27.34 0 1027.33 27.33 27.56 27.56 0 00-27.33-27.33"
								fill-rule="evenodd"
							/>
						</svg>
					</a>
					<a href="https://www.facebook.com/" target="blank">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path d="M290.83 480V276h69.66l9.95-79.6h-79.61v-49.74c0-22.39 7.45-39.79 39.79-39.79h42.28V34.72c-9.91 0-34.8-2.5-62.14-2.5a96 96 0 00-102 104.49v59.69H139.1V276h69.66v204z" />
						</svg>
					</a>
					<a href="https://twitter.com/" target="blank">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path d="M173.66 427.69C316.7 429 433.7 314.08 435 171v-17.1a202.14 202.14 0 0044.8-47.3 206.69 206.69 0 01-52.26 15 96.86 96.86 0 0039.81-49.79 228.61 228.61 0 01-57.25 22.4 88.89 88.89 0 00-67.2-29.91 93.5 93.5 0 00-92.09 92.1 48.4 48.4 0 002.49 19.9A257.29 257.29 0 0164.15 79.24a95.42 95.42 0 00-12.45 47.3 98.89 98.89 0 0039.81 77.15 83.83 83.83 0 01-42.3-12.45 91 91 0 0074.65 89.6 76.8 76.8 0 01-24.86 2.5 42.44 42.44 0 01-17.41-2.5 94.31 94.31 0 0087.1 64.71A188 188 0 0154.2 385.36a68.89 68.89 0 01-22.4-2.5 234.88 234.88 0 00141.86 44.8" />
						</svg>
					</a>
				</div>
			)}
		</footer>
	);
};

export default Footer;
